.card {
  display: flex;
  flex-direction: column;
  background-color: #2d2d2d;
  color: white;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), inset 0 0 10px rgba(255, 255, 255, 0.2);
  transition: transform 0.2s;
  font-size: 16px;
  position: relative;
  cursor: pointer;
}

.card:hover {
  transform: scale(1.02);
  background-color: #424242;
}

.card img {
  border-radius: 8px;
  width: 100%;
  min-height: 299px;
  max-height: 300px;
  object-fit: cover;
  margin-bottom: 16px;
}

.card .info {
  flex: 1;
  margin-top: 0;
}

.card .info .flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.card .actions {
  display: none;
  flex-direction: column;
  gap: 16px;
  width: 100%;
}

.card:hover .actions {
  display: flex;
}

.actions button {
  cursor: pointer;
  color: #36ca3d;
  border: 2px solid;
  /*border-bottom: 2px solid transparent;*/
  outline: none;
  padding: 8px 16px;
  border-radius: 8px;
  transition: all 0.3s ease;
}

.actions button:hover {
  border-color: #36ca3d;
  background-color: #36ca3d;
  color: white;
}

.card .actions input {
    visibility: block;
    width: 100%;
  background-color: #2d2d2d;
  color: white;
  padding: 8px;
  border-radius: 4px;
}

.card .actions span {
  color: #e57373;
  font-size: 14px;
}

.card a {
  color: white;
  font-weight: bold;
  padding: 8px 16px;
  border-radius: 4px;
  text-align: center;
  transition: background-color 0.3s;
  font-size: 16px;
  text-decoration: none;
}

.card a:hover {
  background-color: #4caf50;
}
